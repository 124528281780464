<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="10">
        <el-card shadow="always" style="height: 100%">
          <template #header>
            <div class="flex_center0">账号信息</div>
          </template>
          <div class="flex_center0">
            <img
              :src="userInfo?.avatar || '@assets/img/uimg/img-avatar01.jpg'"
              height="80"
            />
          </div>

          <p class="mt-10 flex_center0">{{ userInfo?.nickname || "--" }}</p>
          <p class="flex_center0">账号:{{ userInfo?.username || "--" }}</p>
          <p class="flex_center0 m_desc">
            坐席标识：[{{ userInfo?.seat_info?.id || "--" }}]
          </p>
          <p class="flex_center0 m_desc">
            坐席有效期至：:{{ userInfo?.seat_info?.expire_time || "--" }}
          </p>
        </el-card>
      </el-col>
      <el-col :span="14">
        <el-card shadow="always">
          <template #header>
            <div class="flex_center0">
              微信绑定
              <i
                v-if="userInfo.band_status === '1'"
                style="margin-left: 5px"
                class="iconfont icon-gouxuan1 m_color_s"
              ></i>
              <i
                v-else
                style="margin-left: 5px"
                class="iconfont icon-Shapex m_color_e"
              ></i>
            </div>
          </template>
          <div>
            <div
              v-if="userInfo.band_status === '0'"
              :Loading="bindWechatLoading"
            >
              <div class="m_color_e flex_center0">
                【您还未绑定微信，绑定后可扫码登录系统】
              </div>
              <div class="m_desc flex_center0">
                <el-button type="primary" link @click="handleGetBindWechatInfo">刷新授权码</el-button>
                <!-- 点击下方『微信快捷登录』按钮绑定您的微信 -->
              </div>
              <!-- <div class="flex_center0">
                  <el-button type="primary" @click="handleGetBindWechatInfo">点击绑定</el-button>
              </div> -->
              <div class="m_desc flex_center0" style="margin-top: 20px">
                <wxlogin
                  :self_redirect="true"
                  :appid="weixinBindInfoRef.appid"
                  :scope="weixinBindInfoRef.scope"
                  :redirect_uri="
                    encodeURIComponent(weixinBindInfoRef.redirect_uri)
                  "
                  :state="weixinBindInfoRef.state"
                >
                </wxlogin>
                <!-- {{ weixinBindInfoRef }} -->
              </div>
              <!-- <div id="bindWechatIframe"></div> -->
            </div>
            <div v-else>
              <div style="color: #44c553" class="flex_center0">
                【您已绑定微信，可扫码登录系统】
              </div>
              <div class="flex_center0" style="margin-top: 10px">
                <el-image
                  style="width: 80px; height: 80px"
                  :src="userInfo.headimgurl"
                  :fit="'fill'"
                />
              </div>
              <div class="flex_center0">
                {{ userInfo.openname }}
              </div>
              <div class="flex_center0" style="margin-top: 10px">
                <el-button type="danger" @click="handleUnbindWechat"
                  >解绑微信</el-button
                >
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import {
  reactive,
  toRefs,
  inject,
  computed,
  ref,
  onMounted,
  onUnmounted,
  watch,
} from "vue";
import { useStore } from "vuex";
import {
  getBindWechatInfo,
  getBindWechatStatus,
  unBindWechat,
} from "@/api/userapi";
import { Loading } from "element-plus/es/components/loading/src/service.mjs";
import { ElMessage, ElMessageBox } from "element-plus";
import wxlogin from "vue-wxlogin";
const bus = inject("$eventBus");
const wsCmd = inject("wsCmd");
const eventbusCms = inject("eventbusCms");
const store = useStore();
const weixinBindInfoRef = ref({});

// --------- 属性 --------------
const userInfo = computed(() => {
  return store.state.user.user;
});
const activeTab = inject("activeTab");
const dialogVisible = inject("dialogVisible");

const bindWechatLoading = ref(false);

const intervalRef = ref(undefined);

// ---------- 方法 -------------

const handleGetBindWechatInfo = () => {
  if (userInfo.value.band_status === "0") {
    getBindWechatInfo();
  }
};
const handleGetBindWechatStatus = () => {
  getBindWechatStatus();
};

const handleUnbindWechat = () => {
  ElMessageBox.confirm("确定解绑微信登录?", "提示信息", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      try {
        bindWechatLoading.value = true;
        unBindWechat();
      } catch (err) {
        console.error(err);
      }
    })
    .catch(() => {});
};

const handleUnbindWechatResp = (resp) => {
  try {
    if (!resp.code && resp.code !== 0) {
      return;
    }
    if (resp.code !== 1) {
      return;
    }
    let result = resp.data?.result;
    if (result === "1") {
      store.commit("user/UPDATE_USER_WECHATBIND_INFO", { band_status: "0" });
    } else {
      ElMessage.error("解绑失败");
    }
  } catch (err) {
    console.error(err);
  } finally {
    bindWechatLoading.value = false;
  }
};

const handleReceiveBindWechatInfoResp = (loginResp) => {
  try {
    bindWechatLoading.value = true;
    if (!loginResp.code && loginResp.code !== 0) {
      return;
    }
    if (loginResp.code !== 1) {
      return;
    }
    weixinBindInfoRef.value = loginResp.data;
    if (intervalRef.value) {
      clearInterval(intervalRef.value);
    }
    intervalRef.value = setInterval(() => {
      handleGetBindWechatStatus();
    }, 2000);
  } catch (err) {
    console.error(err);
  } finally {
    bindWechatLoading.value = false;
  }
};
const handleReceiveBindWechatStatusResp = (resp) => {
  try {
    bindWechatLoading.value = true;
    if (!resp.code && resp.code !== 0) {
      return;
    }
    if (resp.code !== 1) {
      return;
    }
    let band_status = resp.data?.band_status;
    if (band_status === "1") {
      store.commit("user/UPDATE_USER_WECHATBIND_INFO", resp.data);
      if (intervalRef.value) {
        clearInterval(intervalRef.value);
      }
    }
  } catch (err) {
    console.error(err);
  } finally {
    bindWechatLoading.value = false;
  }
};


// -------------- 生命周期 ------------
onMounted(() => {
  bus.on(`${wsCmd.cmd_24_resp}`, handleReceiveBindWechatInfoResp); // listen
  bus.on(`${wsCmd.cmd_25_resp}`, handleReceiveBindWechatStatusResp); // listen
  bus.on(`${wsCmd.cmd_28_resp}`, handleUnbindWechatResp); // listen
});

onUnmounted(() => {
  bus.off(`${wsCmd.cmd_24_resp}`);
});

watch(
  () => {
    return activeTab.value;
  },
  (newValue) => {
    if (newValue === "1") {
      handleGetBindWechatInfo();
    }
  }
);
watch(
  () => {
    return dialogVisible.value;
  },
  (newValue) => {
    if (newValue === false) {
      if (intervalRef.value) {
        clearInterval(intervalRef.value);
      }
    } else {
      handleGetBindWechatInfo();
    }
  }
);
</script>

<style lang="scss" scoped>
.item_title {
  background-color: red;
}
</style>