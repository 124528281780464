import {
  getToken,
  setToken,
  setUserinfo,
  getUserinfo,
  removeUserinfo,
  removeToken,
} from "@/utils/auth";
import {
  setRightBarWidth,
  getRightBarWidth,
  unrememberPassword,
  rememberPassword,
  getMe,
} from "@/utils/userSetting";

const state = {
  user: getUserinfo() || null,
  token: getToken() || null,
  // 窗口皮肤
  skin: require("@assets/img/skin/img-skin04.jpg"),
  isWinMaximize: true,
  isSideCollapsed: false,
  //左侧账号列表是否收缩
  isLeftCollapsed: false,
  winType: "",
  userName: "",
  rightBarWidth: getRightBarWidth(),
  syb_webchat_me: getMe(),
};

const mutations = {
  SET_RIGHT_BAR_WIDTH(state, data) {
    state.rightBarWidth = data;
    setRightBarWidth(data);
  },

  SET_LEFT_COLLAPSED_TOGGLED(state) {
    state.isLeftCollapsed = !state.isLeftCollapsed;
  },
  SET_USER_NAME(state, data) {
    state.userName = data;
  },
  SET_USER(state, data) {
    setUserinfo(data);
    state.user = data;
  },
  UPDATE_USER(state, data) {
    //如果信息没有token则不替换token
    if (!data.token) {
      data.token = state.user.token;
    } else {
      setToken(data.token);
      state.token = data.token;
    }
    let _user = Object.assign(state.user,data)
    setUserinfo(_user);
    // state.user = data;
  },
  UPDATE_USER_WECHATBIND_INFO(state, wechatBindInfo) {
    //如果信息没有token则不替换token
    let user = state.user
    user.band_status = wechatBindInfo.band_status
    user.openname = wechatBindInfo.openname
    user.headimgurl = wechatBindInfo.headimgurl

    setUserinfo(user);
    state.user = user;
  },
  SET_TOKEN(state, data) {
    setToken(data);
    state.token = data;
  },
  SET_WINMAXIMIZE(state, data) {
    localStorage.setItem("isWinMaximize", data);
    state.isWinMaximize = data;
  },
  SET_SKIN(state, data) {
    localStorage.setItem("skin", data);
    state.skin = data;
  },
  SET_SIDECOLLAPSED(state, data) {
    localStorage.setItem("isSideCollapsed", data);
    state.isSideCollapsed = data;
  },
  LOGOUT(state) {
    removeUserinfo();
    removeToken();
    localStorage.setItem("isWinMaximize", true);
    state.user = null;
    state.token = null;
    state.isWinMaximize = true;
  },
  SET_REMEMBER_ME(state, data) {
    state.syb_webchat_me = data;
  },
};

const actions = {
  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("LOGOUT");
      resolve();
    });
  },

  //记住我
  rememberPassword({ commit }, data) {
    return new Promise((resolve) => {
      commit("SET_REMEMBER_ME", data);
      rememberPassword(data);
      resolve();
    });
  },
  //记住我
  unrememberPassword({ commit }) {
    return new Promise((resolve) => {
      commit("SET_REMEMBER_ME", {});
      unrememberPassword();
      resolve();
    });
  },
};
const getters = {
  // getUserInfo: (state, getters, rootState) => {
  getUserInfo: (state) => {
    let user = getUserinfo();
    state.user = user;
    return user;
  },
  getToken(state) {
    let token = getToken();
    state.token = token;
    return token;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
